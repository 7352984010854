import { React, Component } from 'react';
import './Home.css';
import 'react-bootstrap';
import { Season1 } from '../Season1/Season1';
import { Season2 } from '../Season2/Season2';

export class home extends Component {
    constructor() {
        super();
        this.state = {
            current: "s1"
        };
    }

    switchView = (e) => {
        this.setView(e.target.value)
    }

    setView = (current) => {
        this.setState({
            current
        });
    }

    render() {
        return (
            <div class="container">
                <div class="col">
                    <div class="d-flex justify-content-center">
                        <select onChange={this.switchView} class="selectpicker">
                            <option value="s1">Seizoen 1</option>
                            <option value="s2">Seizoen 2</option>
                        </select>
                    </div>
                </div>
                {this.state.current == "s1" ? <Season1> </Season1> : null}
                {this.state.current == "s2" ? <Season2> </Season2> : null}
            </div>
        );
    }
}
