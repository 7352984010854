import { React, Component } from 'react';
import './Episode.css';
import 'react-bootstrap';
import logo from "./friendshowEP.jpeg";

export class Episode extends Component {
    render() {
        return (
            <div class="col-md">
                <a href={this.props.url}>
                    <img src={this.props.img} id="released" alt=""></img><br /></a>
                <h4>{this.props.title}</h4><br />
                <p>{this.props.text}</p>
            </div>
        );
    }
}
