import { React, Component } from 'react';
import './Season1.css';
import 'react-bootstrap';
import { Episode } from '../Episode/Episode';

//All image imports
import logo from "./friendshowEP.jpeg";
import tortuga from "./tortspec.jpeg";
import ep1 from "./ep1.jpeg";
import ep2 from "./ep2.jpeg";
import ep3 from "./ep3.jpeg";
import ep4 from "./ep4.jpeg";
import ep5 from "./ep5.jpeg";
import ep6 from "./ep6.jpeg";
import ep7 from "./ep7.jpeg";

 
export class Season1 extends Component {
    render() {
        return (
            <div class="row">
                <Episode title="EP1. Vrijetijd en hobby's" text="Tijd vrij, of vrije tijd?" url="https://open.spotify.com/episode/47hon4ochGN0SmevW40sqa?si=ba532c16aa61436b" img={ep1}> </Episode>
                <Episode title="EP2. Doe maar gewoontjes" text="Goede en slechte gewoontes; en alles ertussenin" url="https://open.spotify.com/episode/3duGZw6nLec8dF6uSjbG8n?si=mBYQ7dw9TxK_vSDTeHBhFg&dl_branch=1" img={ep2}> </Episode>
                <Episode title="EP3. Vakanties" text="Tuinzania of woonkameroen?" url="https://open.spotify.com/episode/5S7XWPO4ruQnlCwBlJGJhv?si=2_MtwpllRIOlCNOhc0DkGQ" img={ep3}> </Episode>
                <Episode title="EP4. Complottheorie&euml;n" text="Is jouw aarde wel rond?" url="https://open.spotify.com/episode/2FmHkWpJEb4HcdpmttJNiD?si=KHdnEDD9TqCAAor7rem2dg" img={ep4}> </Episode>
                <Episode title="EP5. Duurzaam maar zeker" text="Alles over duurzaamheid" url="https://open.spotify.com/episode/3duGZw6nLec8dF6uSjbG8n?si=mBYQ7dw9TxK_vSDTeHBhFg&dl_branch=1" img={ep5}> </Episode>
                <Episode title="EP6. Carri&egrave;re" text="Van zakgeld naar een zak geld" url="https://open.spotify.com/episode/5Olvh149WCCKmsYQptZY4a?si=1683176d149f4287" img={ep6}> </Episode>
                <Episode title="EP7. Reflectie" text="Terugkijken op dit seizoen" url="https://open.spotify.com/episode/78GaWEVtvG6noL9UUQ983J?si=30b3469645464c91" img={ep7}> </Episode>
                <Episode title="SPECIAL. Friendshow x Tortuga" text="Klinkt als muziek in de oren!" url="https://open.spotify.com/episode/4DYTpaiF75pLxI3xMUCzuF?si=8639ba0663814ed5" img={tortuga}> </Episode>
            </div>
        );
    }
}
