import React, { Component } from 'react';
import swal from 'sweetalert';
import{ init } from 'emailjs-com';
import "./Subscribe.css";
init("user_pYvSc9uUm9s43GnU3oHGU");


export default class Subscribe extends Component {
    constructor(props) {
      super(props);
      this.state = {
        fname: "",
        lname: "",
        leeftijd:"",
        stad:"",
        email: ""
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    };

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit(event){
        event.preventDefault()
        const templateId= 'template_74omyb1';
        this.sendFeedback(templateId, {
            naam: this.state.fname,
            lnaam: this.state.lname,
            age: this.state.leeftijd,
            city: this.state.stad,
            mail: this.state.email})
        this.setState({
            fname: '',
            lname: '',
            leeftijd:'',
            stad:'',
            email: ''
        })
    }

    sendFeedback(templateId	, variables){
        window.emailjs.send(
            "service_u886y1u", templateId,
            variables
        ).then(res=>{
            swal('Bedankt voor het abonneren!', 'Je hoort van ons ;)')
        })
        .catch(err=>console.error('Error:', err)
        )
    }


render(){
    return (
       <div>
          <h1 id="title">Wat leuk dat je wilt abonneren!</h1>
          <p id="text">Op de hoogte blijven van de nieuwste video's en ontwikkelingen omtremt onze podcast? 
              Vul onderstaand formulier in en word op de hoogte gehouden via jouw mailbox!</p>
              <p id="disclaimer">*Alleen een emailadres en voornaam is verplicht. 
              Andere velden zijn geheel vrijblijvend, maar het zou ons een plezier doen als je ze invult!</p>
            <div class="form">
                <form onSubmit={this.handleSubmit}>
                    <label for = "fname" id="labels">Naam:</label><br></br>
                    <input type="text" id="fname" name="fname" required value={this.state.fname} onChange={this.handleChange}></input><br></br>
                    <label for = "lname" id="labels">Achternaam:</label><br></br>
                    <input type="text" id="lname" name="lname" value={this.state.lname} onChange={this.handleChange}></input><br></br>
                    <label for = "leeftijd" id="labels">Leeftijd:</label><br></br>
                    <input type="number" id="leeftijd" name="leeftijd" value={this.state.leeftijd} onChange={this.handleChange}></input><br></br>
                    <label for = "email" id="labels" >Email:</label><br></br>
                    <input type="email" id="email" name="email" required value={this.state.email} onChange={this.handleChange}></input><br></br>
                    <label for = "stad" id="labels" >Stad:</label><br></br>
                    <input type="text" id="stad" name="stad" value={this.state.stad} onChange={this.handleChange}></input><br></br>
                    <button type="submit" class="btn btn-primary" id="verstuur">Verstuur</button>
                </form>
            </div>
       </div>
    );
}
}