import React from 'react';
import homeButton from "./home.png"
import "./ErrorPage.css"
import { useHistory } from 'react-router-dom';


const Error = () => {
    const history = useHistory();
    const handleClick = () => history.push('/');
    return (
        <div>
            <h1 id="errortitle">Oepsss...</h1><br/>
            <div className='returntext'>
                <p>Je hebt de duistere uithoek van deze website gevonden.</p><br/>
                <p>Gelukkig is terug gaan naar een veilige plek simpel; klik op deze net iets te grote Home-knop</p>
            </div>
            <img src={homeButton} id='homeButton' onClick={handleClick} alt=""/>
        </div>
    );

}
 
export default Error;