import './App.css';
import React from 'react';
import Navbar from './components/Navbar/Navbar'; 
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { home } from './components/Home/Home';
import Admin from './components/Admin/Admin';
import Subscribe from './components/Subscribe/Subscribe';
import Error from './components/ErrorPage/ErrorPage';
import Contact from './components/Contact/Contact';



function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
              <Switch>
                  <Route exact path="/" component={home} />
                  <Route path="/subscribe" component={Subscribe} />
                  <Route path="/supersecretadminpanel" component={Admin} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/404" component={Error}/>
                  <Redirect to= "/404"/>
              </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
