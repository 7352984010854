import { React, Component } from 'react';
import './Season2.css';
import 'react-bootstrap';
import logo from "./friendshowEP.jpeg";
import { Episode } from '../Episode/Episode';

export class Season2 extends Component {
    render() {
        return (
            <div class="row">
                <Episode title="EP1. Een eigen plek" text="Een plek onder de zon" url="https://open.spotify.com/episode/4dclRxltd1OecryMwhChGk?si=9f86f6e2822c458b" img={logo}> </Episode>
                <Episode title="EP2. Stadscultuur" text="Van melkboer naar flitsbezorger" url="https://open.spotify.com/episode/0YGKe1x2k6ChL9HjPIdE0a?si=98e22b64eefe46e0" img={logo}> </Episode>
                <Episode title="EP3. De kwartlife crisis" text="Onzekerheden en keuzes met invloed" url="https://open.spotify.com/episode/2phJPhVCngACIXYOwIvWRB?si=d562a7e808864d7e" img={logo}> </Episode>
            </div>
        );
    }
}
