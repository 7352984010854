import React from 'react';
import './Admin.css';


const Admin= () => {
    return (
       <div>
          <h1>Admin panel</h1>
           <p>Make upload and modification options</p>
       </div>
    );
}
 
export default Admin;