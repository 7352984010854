import React, { Component } from 'react';
import email from "./email.png";
import instagram from "./instagram.png";
import "./Contact.css";
import 'react-bootstrap';

export default class Contact extends Component {
    constructor(props) {
        super(props);
    };

    openInsta() {
        window.open('https://www.instagram.com/the_friendshow/?igshid=ioxb4n6gohzc')
    }

    sendMail() {
        window.open('mailto:the_friendshow@gmail.com?subject=Subject&body=Hi%20Friends%20,%20');
    };

    render() {
        return (
            <div>
                <h1 id="errortitle">Kom in contact! test</h1><br />
                <div className='returntext'>
                    <p>Iets te melden aan ons? Denk hierbij aan idee&#235;n, opmerkingen, of eventuele samenwerkingen.</p><br />
                    <p>Wij zijn bereikbaar via onderstaande socials; spreek je snel!</p>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="col-md" id='noborder1'>
                            <img src={email} id='email' onClick={this.sendMail()} alt="" />
                            <p>Email</p>
                        </div>
                        <div class="col-md" id='noborder'>
                            <img src={instagram} id='instagram' onClick={this.openInsta()} alt="" />
                            <p>Instagram</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
