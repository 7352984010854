export const MenuItems =[
    {
        title: 'Home',
        url: '/',
        cName: 'nav-links'
    },    {
        title: 'Spotify',
        url: 'https://open.spotify.com/show/1QQ52g9k5bu3jRbHgHf80K?si=povg6G7JQE6N3DvS3jlwKg&dl_branch=1',
        cName: 'nav-links'
    },    {
        title: 'Instagram',
        url: 'https://instagram.com/the_friendshow?igshid=ioxb4n6gohzc',
        cName: 'nav-links'
    },    {
        title: 'Contact',
        url: '/contact',
        cName: 'nav-links'
    },    {
        title: 'Abonneer',
        url: '/subscribe',
        cName: 'nav-links-mobile'
    },
]